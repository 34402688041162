<template>
  <div style="background-color: #27293d; height: 100%; position: fixed; width: 200px">
    <ul class="" style="height: 100%">
      <li class="py-3">
        <div class="flex items-center bg-white mx-auto" style="border-radius: 50%; width: 150px; height: 150px">
         <img src="@/assets/TIZETI.png" alt="logo" class="logo w-full" style="height: 100px">
        </div>
      </li>
      <li class="mt-6 pl-3">
        <div class="flex items-center gap-4">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 21H13V15H21V21ZM11 21H3V11H11V21ZM21 13H13V3H21V13ZM11 9H3V3H11V9Z" fill="#fff"></path>
          </svg>
          <router-link to="/app/dashboard/home" class="text-sm text-white" :class="[$route.name === 'Menu' ? 'text-base font-bold text-blue-500': '']">Dashboard</router-link>
        </div>
      </li>
      <li class="mt-8 pl-3 ">
        <div class="flex items-center gap-4">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M18.9999 20H5.99992C2.87429 20.0018 0.270809 17.6038 0.0162787 14.4886C-0.238251 11.3733 1.94144 8.58453 5.02592 8.07899C6.44563 5.56188 9.11003 4.0035 11.9999 3.99993C13.8019 3.99312 15.5524 4.60129 16.9619 5.72399C18.346 6.82185 19.33 8.34497 19.7619 10.058C22.3458 10.455 24.1877 12.7755 23.9879 15.3821C23.7882 17.9887 21.6141 20.0014 18.9999 20ZM11.9999 5.99998C9.83163 6.00255 7.83259 7.17209 6.76792 9.06099L6.29992 9.89999L5.35091 10.055C3.3012 10.3984 1.85592 12.2543 2.02513 14.3257C2.19433 16.3971 3.92164 17.9938 5.99992 18H18.9999C20.5685 18.0016 21.8735 16.7946 21.9941 15.2307C22.1147 13.6667 21.0102 12.2739 19.4599 12.035L18.1439 11.835L17.8219 10.543C17.1572 7.8698 14.7545 5.99495 11.9999 5.99998ZM13.4499 16H10.5499V13H7.99992L11.9999 8.99999L15.9999 13H13.4499V16Z" fill="#fff"></path>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="24" height="24" fill="white"></rect>
            </clipPath>
            </defs>
          </svg>
          <router-link to="/app/dashboard/upload" class="text-sm text-white" :class="[$route.name === 'Upload' ? 'text-base font-bold text-blue-500': '']">Upload Enrollees</router-link>
        </div>
      </li>
       <li class="mt-8 pl-3">
        <div class="flex items-center gap-4">
          <svg width="20" height="20" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 9.5H3C3.26522 9.5 3.51957 9.39464 3.70711 9.20711C3.89464 9.01957 4 8.76522 4 8.5C4 8.23478 3.89464 7.98043 3.70711 7.79289C3.51957 7.60536 3.26522 7.5 3 7.5H2V5.5H3C3.26522 5.5 3.51957 5.39464 3.70711 5.20711C3.89464 5.01957 4 4.76522 4 4.5C4 4.23478 3.89464 3.98043 3.70711 3.79289C3.51957 3.60536 3.26522 3.5 3 3.5H2V2.5C2 1.96957 2.21071 1.46086 2.58579 1.08579C2.96086 0.710714 3.46957 0.5 4 0.5L14 0.5C14.5304 0.5 15.0391 0.710714 15.4142 1.08579C15.7893 1.46086 16 1.96957 16 2.5V14.5C16 15.0304 15.7893 15.5391 15.4142 15.9142C15.0391 16.2893 14.5304 16.5 14 16.5H4C3.46957 16.5 2.96086 16.2893 2.58579 15.9142C2.21071 15.5391 2 15.0304 2 14.5V13.5H3C3.26522 13.5 3.51957 13.3946 3.70711 13.2071C3.89464 13.0196 4 12.7652 4 12.5C4 12.2348 3.89464 11.9804 3.70711 11.7929C3.51957 11.6054 3.26522 11.5 3 11.5H2V9.5Z" fill="#fff"/>
            <path d="M6 10.5H8V13.5H6V10.5ZM9 7.5H11V13.5H9V7.5ZM12 3.5H14V13.5H12V3.5ZM0 12.5C0 12.2348 0.105357 11.9804 0.292893 11.7929C0.48043 11.6054 0.734784 11.5 1 11.5H2V13.5H1C0.734784 13.5 0.48043 13.3946 0.292893 13.2071C0.105357 13.0196 0 12.7652 0 12.5ZM0 8.5C0 8.23478 0.105357 7.98043 0.292893 7.79289C0.48043 7.60536 0.734784 7.5 1 7.5H2V9.5H1C0.734784 9.5 0.48043 9.39464 0.292893 9.20711C0.105357 9.01957 0 8.76522 0 8.5ZM0 4.5C0 4.23478 0.105357 3.98043 0.292893 3.79289C0.48043 3.60536 0.734784 3.5 1 3.5H2V5.5H1C0.734784 5.5 0.48043 5.39464 0.292893 5.20711C0.105357 5.01957 0 4.76522 0 4.5Z" fill="#fff"/>
          </svg>
          <router-link to="/app/dashboard/viewpolicies" class="text-sm text-white" :class="[$route.name === 'ViewPolicies' ? 'text-base font-bold text-blue-500': '']">View Policies</router-link>
        </div>
      </li>
      <li class="mt-8 pl-3">
        <div class="flex items-center gap-4 cursor-pointer" @click="logout">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z" fill="#fff"></path>
          </svg>
          <p class="text-sm text-white">Logout</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data(){
    return {
      
    }
  },
  methods:{
    logout(){
      this.$store.commit('startLoading')
      this.$store.dispatch('logoutUser')
      .then(()=>{
        this.$router.push('/')
        this.$store.commit('endLoading')
      })
    }
  }
}
</script>

<style scoped>
.blue{
  color: #5469D4
}
.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: purple;
}

input:focus + .slider {
  box-shadow: 0 0 1px purple;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* li a.router-link-exact-active {
  color: #5469D4
}
li a.router-link-exact-active ~ svg {
  color: #5469D4
} */
</style>