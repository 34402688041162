<template>
  <div class="home">
    <div class="wrapper w-full">
      <div class="l-modal block lg:hidden" v-if="$store.state.showMobileNav">
        <MobileNav />
      </div>
      <div class="aside hidden lg:block w-full border-r border-solid border-gray-200" style="box-shadow: inset -1px 0px 0px #E3E8EE">
        <TheSidebar />
      </div>
      <div class="p-3 flex justify-between items-center lg:hidden" style="background-color: #1e1e2f">
        <div class="rounded border border-solid p-2 border-green-500 flex items-center gap-2" @click="$store.commit('showMobileNav', true)">
          <p class="text-white">Menu</p>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 18H3V16H12V18ZM21 13H3V11H21V13ZM21 8H12V6H21V8Z" fill="#10B981"></path>
          </svg>
        </div>
        <img src="@/assets/logo.svg" alt="logo" class="logo" style="width: 100px; height:50px">
      </div>
      <div class="w-full overflow-x-hidden" style="background-color: #1e1e2f">
        <div class="">
          <main class="">
            <router-view :key="$route.path"></router-view>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TheSidebar from '@/components/TheSidebar.vue'
import MobileNav from '@/components/MobileNav.vue'

export default {
  components:{
    TheSidebar, MobileNav
  }

}
</script>
<style scoped>
.home{
  height: 100%
}

@media only screen and (min-width: 1024px){
  .wrapper{
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
    min-height: 100vh;
    width: 100%;
  }
 
}

</style>